<template>
  <section
    class="hero is-fullheight is-pulled-up"
    :class="{ 'has-background-info': $experian }"
  >
    <div class="hero-body mobile-background" :style="hero">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-4-widescreen is-6-desktop is-8-tablet">
            <div class="content">
              <span class="title is-5 auth-text">
                <span class="is-marginless">Can’t log in?</span>
              </span>
            </div>
            <form @submit.prevent="submit">
              <div class="field">
                <input
                  type="text"
                  name="email"
                  v-model="email"
                  class="input"
                  placeholder="Email address"
                  v-validate.disable="'required|email'"
                  :class="{ 'is-danger': errors.has('email') }"
                  data-vv-as="email"
                />
                <span class="help has-text-danger" v-if="errors.has('email')">
                  {{ errors.first('email') }}
                </span>
              </div>
              <div class="field has-text-danger" v-if="error">
                <span class="icon is-valigned">
                  <i class="fal fa-times" />
                </span>
                <span class="is-valigned">{{ error }}</span>
              </div>
              <p class="auth-text is-size-7 field">
                If you can’t remember your password, please enter your email
                address above and hit continue and we’ll email you a link to
                reset your password.
              </p>
              <div class="columns is-mobile is-align-items-center">
                <p class="column is-size-7 auth-text">
                  If you’re experiencing any other issues logging in or you
                  don’t receive your password reset email, please contact our
                  dedicated support team at
                  <a href="mailto:business-support@percayso.com">
                    business-support@percayso.com
                  </a>
                </p>
                <div class="column is-narrow buttons is-right">
                  <button
                    class="button is-size-6-mobile is-info"
                    :class="{ 'is-loading': $wait.is('loading') }"
                    :disabled="$wait.is('loading')"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { passwordRequest } from 'modules/account/services'
import { heroStyle } from 'modules/ui/services'
export default {
  name: 'AuthPasswordRequest',
  data: () => ({ email: '', error: null }),
  methods: {
    async submit() {
      const valid = await this.$validator.validate()
      if (!valid) return
      try {
        this.$wait.start('loading')
        await passwordRequest({ email: this.email })
        this.$router.push('/')
        this.$notify({ group: 'success', text: 'Request sent' })
      } catch (err) {
        const fallback = 'We were unable to complete your request'
        this.error = err?.response?.data?.error?.message ?? fallback
      } finally {
        this.$wait.end('loading')
      }
    }
  },
  computed: {
    hero() {
      return this.$mq !== 'mobile' ? heroStyle() : { background: 'none' }
    }
  }
}
</script>

<style lang="sass">
a
  color: $authentication-link-color !important
</style>